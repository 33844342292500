// ProcessingFeeTooltip.vue
<template>
  <span>
    {{ label }}
    <b-popover
      :target="popoverId"
      triggers="hover focus"
      placement="bottom"
      :custom-class="customClass"
    >
      <template #title
        >{{ popoverTitle }}
        {{ processingFee ? `${processingFee}%` : "" }}</template
      >
      <div class="popover-content-wrapper">
        <!-- <div class="popover-content" v-html="popoverContent"></div> -->
        <div class="popover-content">
          <p>
            ถ้าในใบเสร็จมีการชำระเงินหลายวิธี และมีค่าธรรมเนียม เช่น
            ค่าธรรมเนียมบัตรเครดิต
            ค่าธรรมเนียมนั้นจะถูกเฉลี่ยไปตามส่วนของแต่ละรายการในใบเสร็จ
          </p>
          <p><strong>ยกตัวอย่าง:</strong></p>
          <ul>
            <li>
              ใบเสร็จมีค่าใช้จ่ายรวม 1,000 บาท แบ่งเป็น:
              <ul>
                <li>ค่าขูดหินปูน 900 บาท</li>
                <li>ค่าปลอดเชื้อ 100 บาท</li>
              </ul>
            </li>
            <li>
              มีการชำระเงิน 2 วิธี:
              <ul>
                <li>ชำระเงินสด 100 บาท</li>
                <li>ชำระด้วยบัตรเครดิต 900 บาท (ซึ่งมีค่าธรรมเนียม 3%)</li>
              </ul>
            </li>
          </ul>
          <p><strong>การคำนวณค่าธรรมเนียมบัตรเครดิต:</strong></p>
          <ul>
            <li>ค่าธรรมเนียมบัตรเครดิต = 900 * 3% = 27 บาท</li>
          </ul>
          <p>
            จากนั้นจะนำค่าธรรมเนียม 27
            บาทนี้มาเฉลี่ยให้กับแต่ละรายการตามสัดส่วนของค่าใช้จ่าย:
          </p>
          <ol>
            <li>
              <strong>ค่าขูดหินปูน</strong>:
              <ul>
                <li>
                  เนื่องจากค่าขูดหินปูนคิดเป็น 900 บาทจากทั้งหมด 1,000 บาท (หรือ
                  90% ของค่าใช้จ่ายทั้งหมด)
                </li>
                <li>
                  ค่าธรรมเนียมที่ต้องจ่ายในส่วนนี้คือ (900/1000) * 27 = 24.3 บาท
                </li>
              </ul>
            </li>
            <li>
              <strong>ค่าปลอดเชื้อ</strong>:
              <ul>
                <li>
                  ค่าปลอดเชื้อคิดเป็น 100 บาทจากทั้งหมด 1,000 บาท (หรือ 10%
                  ของค่าใช้จ่ายทั้งหมด)
                </li>
                <li>
                  ค่าธรรมเนียมที่ต้องจ่ายในส่วนนี้คือ (100/1000) * 27 = 2.7 บาท
                </li>
              </ul>
            </li>
          </ol>
          <p><strong>สรุป:</strong></p>
          <ul>
            <li>ค่าธรรมเนียมสำหรับค่าขูดหินปูนคือ 24.3 บาท</li>
            <li>ค่าธรรมเนียมสำหรับค่าปลอดเชื้อคือ 2.7 บาท</li>
          </ul>
        </div>
      </div>
    </b-popover>
    <i :id="popoverId" class="fas fa-info-circle"></i>
  </span>
</template>

<script>
export default {
  name: "ProcessingFeeTooltip",
  props: {
    label: {
      type: String,
      required: true,
    },
    popoverId: {
      type: String,
      default() {
        return `popover-${this._uid}`;
      },
    },
    popoverTitle: {
      type: String,
      default: "คำอธิบายค่าธรรมเนียม",
    },
    processingFee: {
      type: Number,
    },
    // popoverContent: {
    //   type: String,
    //   required: true,
    // },
    customClass: {
      type: String,
      default: "processing-fee-popover",
    },
  },
};
</script>

<style scoped>
.processing-fee-popover {
  max-width: 400px;
  width: 400px;
}

.popover-content-wrapper {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
}

.popover-content {
  font-size: 0.9rem;
}

.popover-content ul,
.popover-content ol {
  padding-left: 20px;
}

.popover-content li {
  margin-bottom: 5px;
}
</style>