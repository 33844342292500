<template>
  <div class="content-wrapper view-aptlist-manage pt-3">
    <div class="content">
      <b-card>
        <h4><i class="fas fa-chart-bar"></i> รายงานรายได้สาขา</h4>
        <b-card no-body class="mt-1 mb-0 pb-0">
          <b-tabs class="mb-0" v-model="tabIndex" card>
            <b-tab lazy title="รายการรักษา" @click="saveTabIndex">
              <ItemsIncomeReport />
            </b-tab>
            <b-tab lazy title="ใบเสร็จ" @click="saveTabIndex">
              <ReceiptIncomeReport />
            </b-tab>
            <b-tab lazy title="วิธีชำระ" @click="saveTabIndex">
              <PaymentMethodReport />
            </b-tab>
            <b-tab lazy title="รายได้แพทย์" @click="saveTabIndex">
              <DoctorsIncomeReport />
            </b-tab>
            <b-tab lazy title="ค่าใช้จ่ายแลป" @click="saveTabIndex">
              <LabReport />
            </b-tab>
            <b-tab lazy title="ใบเสร็จถูกยกเลิก" @click="saveTabIndex">
              <CancelReceiptReport />
            </b-tab>
            <b-tab lazy title="รายการค้างชำระ" @click="saveTabIndex">
              <OverdueItemReport />
            </b-tab>
            <b-tab lazy title="รายงาน สปสช." @click="saveTabIndex">
              <NHSOReport />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-card>
      <PaymentReceiptPreviewModal
        ref="paymentReceiptPreviewModal"
      ></PaymentReceiptPreviewModal>
      <PdfExport ref="pdfExport" />
      <Dialog ref="Dialog"></Dialog>
      <Loading v-if="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import PdfExport from "@/components/PdfExport";

import ItemsIncomeReport from "@/components/reports/ItemsIncomeReport";
import ReceiptIncomeReport from "@/components/reports/ReceiptIncomeReport";
import DoctorsIncomeReport from "@/components/reports/DoctorsIncomeReport";
import LabReport from "@/components/reports/BranchLabReport";
import PaymentMethodReport from "@/components/reports/PaymentMethodReport";
import CancelReceiptReport from "@/components/reports/CancelReceiptReport";
import OverdueItemReport from "@/components/reports/OverdueItemReport";
import NHSOReport from "@/components/reports/NHSOReport";

import PaymentReceiptPreviewModal from "@/components/modal/PaymentReceiptPreviewModal";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";
import { eventBus } from "../main";

export default {
  name: "BranchIncomeReport",
  components: {
    PdfExport,
    ItemsIncomeReport,
    ReceiptIncomeReport,
    DoctorsIncomeReport,
    LabReport,
    PaymentMethodReport,
    OverdueItemReport,
    NHSOReport,
    Dialog,
    Loading,
    CancelReceiptReport,

    PaymentReceiptPreviewModal,
  },
  data() {
    return {
      isLoading: false,
      tabIndex: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      isManager: "moduleUser/isManager",
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchPatientById: "modulePatient/fetchPatientById",
      fetchReceiptById: "moduleReceipt/fetchReceiptById",
    }),
    init() {},
    moment() {
      return moment();
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    saveTabIndex() {
      this.$nextTick(() => {
        localStorage.setItem("branchReportTabIndex", this.tabIndex);
      });
    },
    openReceiptModal(data) {
      const receiptPromise = this.fetchReceiptById({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        id: data.id,
      });
      const patientPromise = this.fetchPatientById({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        id: data.patientId,
      });

      this.isLoading = true;
      Promise.all([receiptPromise, patientPromise])
        .then(responseAll => {
          if (responseAll) {
            const [receiptRes, patientRes] = responseAll;
            if (receiptRes.status == 200 && patientRes.status == 200) {
              if (receiptRes.data.id && patientRes.data.id) {
                this.$refs.paymentReceiptPreviewModal.show(
                  receiptRes.data,
                  patientRes.data,
                  receiptRes.data.cancel ? "cancel" : "copy",
                  this.isManager
                  // true //displayOnly
                );
              } else {
                eventBus.$emit("alertSwal", {
                  title: "ระบบขัดข้อง",
                  message: "ไม่สามารถแสดงข้อมูลใบเสร็จได้",
                  icon: "error",
                });
              }
            }
          }
        })
        .catch(err => {
          console.error("catch:", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.tabIndex = parseInt(localStorage.getItem("branchReportTabIndex"));
    });
    eventBus.$on("openReceipt", e => {
      this.openReceiptModal(e);
    });
  },
  updated() {},
  destroyed() {},
};
</script>

<style scoped>
</style>
